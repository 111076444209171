/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  datatable_search: 'اكتب للبحث...',
  username: 'اسم مستخدم الدخول',
  name: 'اسمك الكامل',
  mobile: 'رقم الجوال بدون مفتاح الدولة',
  organization_name: 'إسم الشركة أو الاسم التجاري',
  commercial_register: 'الرقم التجاري للمنشأة/الشركة/فرد',
  vat_number: 'الرقم الضريبي في دولتك',
  company_name: 'جهة العمل الخاصة بك'
}
