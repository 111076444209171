<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-btn
    :outlined="outlined"
    :tile="tile"
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    color: {
      type: String,
      default: () => 'primary'
    },
    retainFocusOnClick: {
      type: Boolean,
      default: () => !1
    },
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    tile: {
      type: Boolean,
      default: () => !0
    },
    outlined: {
      type: Boolean,
      default: () => !1
    }
  },
  computed: {
    getClasses () {
      // console.log(this.$attrs.class)
      const classes = [
        'app-btn',
        'my-1'
        // 'ms-1',
      ]
      // console.log(this.$props)
      // if (this.$el && this.$el.getAttribute('class')) {
      //   classes.push(this.$el.getAttribute('class'))
      // console.log();
      // }
      return classes.join(' ')
    }
  }
}
</script>
