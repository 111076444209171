/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Utilities/AppointmentDate')
const Static = StubUrl('Static/AppointmentDate')
const Company = StubUrl(Static('Company'))

export default {
  ...Stub(url()),
  availableDatesOfCompany: id => axios.post(Company(`${id}/AvailableAppointmentDates`)),
  availableTimesOfCompany: (id, date) => axios.post(Company(`${id}/AvailableAppointmentTimes`), { date })
}
