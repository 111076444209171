/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import VueGtag from 'vue-gtag'

// Vue.use(VueGtag, {
//   config: {id: ""},
//   appName: '',
//   pageTrackerScreenviewEnabled: true
// }, router);

export default VueGtag
