/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  appointment: {
    new: 'New Appointment',
    inquiry: 'Appointment inquiry / business men',
    inquiry_subtitle: 'Choose the country and enter your mobile number to inquire about your appointments with companies',
    exhibitor_entry: 'Exhibitor Entry',
    no_times_available: 'Sorry, there are no available times on this date',
    confirm: 'Confirm Appointment',
    created_line: 'Your appointment has been successfully added, waiting for confirmation. Thank you.',
    request: 'Request Appointment',
    website: 'Website',
    test_notifications: 'Testing Notifications',
    statistic: 'Full Statistic'
  },
  expo_header_line: 'B2B Connect:',
  today_appointments: 'Today Appointments',
  tomorrow_appointments: 'Tomorrow Appointments',
  canceled_appointments: 'Canceled Appointments',
  all_appointments: 'All Appointments',
  login_subtitle: 'Choose the country and enter your mobile number to login',
  newSurvey: 'Exhibition Survey',
  newSurvey_subtitle: 'Please fill the required information',
  survey_start_again: 'Start Again'
}
