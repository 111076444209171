/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  user_id: 'User',
  role_id: 'Role',
  roles_id: 'Roles',
  permission_id: 'Permission',
  permissions_id: 'Permissions',
  country_id: 'Country',
  countries_id: 'Countries',
  city_id: 'City',
  cities_id: 'Cities',
  order_id: 'Order',
  orders_id: 'Orders',
  order_status_id: 'Order Status',
  order_statuses_id: 'Order Statuses',
  category_id: 'Category',
  categories_id: 'Categories',
  id: 'ID',
  login_id: 'Login ID',
  username: 'Username',
  name: 'Name',
  name_ar: 'Arabic Name',
  name_en: 'English Name',
  password: 'Password',
  password_confirmation: 'Confirm Password',
  mobile: 'Mobile',
  email: 'Email',
  locale: 'Locale',
  active: 'Active',
  code: 'Code',
  send_time: 'Send Time',
  token: 'Token',
  read_at: 'Read at',
  key: 'Key',
  order_by: 'Order by',
  app_name: 'App Name',
  app_name_ar: 'Arabic App Name',
  app_name_en: 'English App Name',
  description: 'Description',
  description_ar: 'Arabic Description',
  description_en: 'English Description',
  job_title: 'Job Title',
  company: 'Company',
  company_id: 'Company',
  country: 'Country',
  city: 'City',
  phone: 'Phone',
  whatsapp: 'Whatsapp',
  two_factor_auth: 'Two Factor Auth',
  time: 'Time',
  from_time: 'From Time',
  to_time: 'To Time',
  date: 'Date',
  full_date: 'Full Date',
  from_date: 'From date',
  to_date: 'To date',
  type: 'Type',
  status: 'Status',
  push_token: 'Device push token',
  verification_code: 'Verification Code',
  attachment: 'Attachment',
  attachments: 'Attachments',
  notification_email: 'Email notifications',
  notification_mobile: 'Mobile notifications',
  web: 'Website url',
  website: 'Website',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'Customer service email',
  customer_service_mobile: 'Customer service mobile',
  customer_service_phone: 'Customer service phone',
  google_map_url: 'google map url',
  app_store_url: 'App Store url',
  play_store_url: 'Play Store url',
  address: 'Address',
  address_ar: 'Arabic address',
  address_en: 'English address',
  contact_us: 'Contact us',
  contact_us_ar: 'Arabic Contact us',
  contact_us_en: 'English Contact us',
  about_us: 'About us',
  about_us_ar: 'Arabic About us',
  about_us_en: 'English About us',
  privacy_policy: 'Privacy & Policy',
  privacy_policy_ar: 'Arabic Privacy & Policy',
  privacy_policy_en: 'English Privacy & Policy',
  terms_and_conditions: 'Terms & Conditions',
  terms_and_conditions_ar: 'Arabic Terms & Conditions',
  terms_and_conditions_en: 'English Terms & Conditions',
  commercial_register: 'Commercial Register',
  commercial_name: 'Commercial name',
  commercial_name_ar: 'Arabic Commercial name',
  commercial_name_en: 'English Commercial name',
  vat_number: 'VAT number',
  notification_methods: 'Notification Methods',
  avatar: 'Avatar',
  blobAvatar: 'Avatar',
  created_at: 'Created date',
  from_created_at: 'From Created At',
  to_created_at: 'To Created At',
  message: 'Your message',
  price: 'Price',
  notes: 'Notes',
  icon: 'Icon',
  subject: 'Subject',
  image: 'Image',
  images: 'Images',
  choose_image: 'Choose Image',
  update_image: 'Update Image',
  url: 'Url',
  amount: 'Amount',
  discount: 'Discount',
  discount_type: 'Discount Type',
  discount_amount: 'Discount Amount',
  meta: 'Meta',
  quantity: 'Quantity',
  location: 'Location',
  tax: 'TAX',
  new_order_status: 'New Order Status',
  pending_order_status: 'Pending Order Status',
  accepted_order_status: 'Accepted Order Status',
  completed_order_status: 'Completed Order Status',
  canceled_order_status: 'Canceled Order Status',
  fixed_app_fees: 'Fixed App Fees',
  percent_app_fees: 'Percent App Fees',
  payable_id: 'Payable ID',
  payable_type: 'Payable Type',
  total: 'Total',
  data: 'Data',
  from_discount_amount: 'Discount Amount From',
  to_discount_amount: 'Discount Amount To',
  expire_date: 'Expire Date',
  from_expire_date: 'Expire Date From',
  to_expire_date: 'Expire Date To',
  read: 'read',
  ref_key: 'Reference ID',
  attachment_type: 'Attachment Type',
  percentage: 'Percentage',
  vat: 'Vat',
  vat_amount: 'Vat Amount',
  payment_date: 'Payment Date',
  from_payment_date: 'Payment Date From',
  to_payment_date: 'Payment Date To',
  payment_type: 'Payment Type',
  paid_amount: 'Paid Amount',
  color: 'Color',
  symbol: 'Symbol',
  contact: 'Contact',
  start_date: 'Start Date',
  from_start_date: 'From Start_date',
  to_start_date: 'To_start Date',
  end_date: 'End_date',
  from_end_date: 'From End_date',
  to_end_date: 'To_end Date',
  start_time: 'Start Time',
  from_start_time: 'From Start_time',
  to_start_time: 'To_start Time',
  end_time: 'End_time',
  from_end_time: 'From End_time',
  to_end_time: 'To_end Time',
  database: 'App',
  mail: 'E-mail',
  sms: 'SMS',
  pay_date: 'Pay Date',
  from_pay_date: 'From Pay Date',
  to_pay_date: 'To Pay Date',
  due_amount: 'Due Amount',
  customer_name: 'Customer Name',
  signature: 'Signature',
  stamp: 'Stamp',
  other: 'Other',
  sectors: 'Sectors',
  sector_id: 'Sector',
  sectors_id: 'Sectors',
  sectors_hint: 'What sectors matter to you: (3 options at most)',
  company_hint: 'The name of the company to be meeting with:',
  meeting_purpose_id: 'The purpose of the meeting',
  meeting_purposes_id: 'The purpose of the meeting',
  other_meeting_purpose: 'The purpose of the meeting',
  meeting_purpose_hint: 'The purpose of the meeting:',
  appointment_date_id: 'Appointment Date',
  appointment_date_hint: 'Choose Appointment Date:',
  appointment_time_id: 'Appointment Time',
  appointment_time_hint: 'Choose Appointment Time:',
  start_hour: 'Start Hour',
  end_hour: 'End Hour',
  appointment_id: 'Appointment',
  appointments_id: 'Appointments',
  companies_id: 'Companies',
  company_name: 'Company Name',
  available_appointments: 'Available Appointments',
  approved_appointment: 'Confirm',
  reject_appointment: 'Apology',
  pending_appointments_period: 'Minutes of pending appointments',
  new_appointment_text: 'New Appointment-Businessman',
  new_appointment_text_ar: 'AR New Appointment-Businessman',
  new_appointment_text_en: 'EN New Appointment-Businessman',
  company_new_appointment_text: 'New Appointment Text for Company',
  company_new_appointment_text_ar: 'AR New Appointment Text for Company',
  company_new_appointment_text_en: 'EN New Appointment Text for Company',
  approve_appointment_text: 'Approve Appointment-Businessman',
  approve_appointment_text_ar: 'AR Approve Appointment-Businessman',
  approve_appointment_text_en: 'EN Approve Appointment-Businessman',
  reject_appointment_text: 'Apology Appointment-Businessman',
  reject_appointment_text_ar: 'AR Apology Appointment-Businessman',
  reject_appointment_text_en: 'EN Apology Appointment-Businessman',
  no_appointments_text: 'No Appointments-Businessman',
  no_appointments_text_ar: 'AR No Appointments-Businessman',
  no_appointments_text_en: 'EN No Appointments-Businessman',
  stand: 'Stand',
  expo_header: 'Expo Header Image',
  expo_header_image: 'Expo Header Image',
  expo_header_blob: 'Expo Header Image',
  default_password: 'Default Password',
  c_name: 'Exhibitor name',
  c_company: 'Exhibitor company name',
  c_mobile: 'Exhibitor company mobile',
  c_email: 'Exhibitor company email',
  c_stand: 'Exhibitor company stand',
  b_name: 'Businessman name',
  b_company: 'Businessman company',
  b_mobile: 'Businessman mobile',
  b_email: 'Businessman email',
  b_country: 'Businessman country',
  b_website: 'Businessman website',
  meeting_purpose: 'Meeting Purpose',
  company_rejected_success_text: 'Exhibitor apology',
  company_rejected_success_text_ar: 'AR Exhibitor apology',
  company_rejected_success_text_en: 'EN Exhibitor apology',
  company_approved_success_text: 'Exhibitor approved',
  company_approved_success_text_ar: 'AR Exhibitor approved',
  company_approved_success_text_en: 'EN Exhibitor approved',
  appointment_minutes: 'Appointment Minutes',
  survey1: 'How well did the Hosted Buyers match your target audience?',
  survey2: 'Were the Hosted Buyers key decision-makers in their companies?',
  survey3: 'Did the Hosted Buyers show genuine interest in your products/services?',
  survey4: 'How would you rate the number of Hosted Buyers you consider potential future clients?',
  survey5: 'How would you rate your success in securing business deals or partnerships through the Hosted Buyers program?',
  survey6: 'How can we improve the Hosted Buyers program to better meet your needs?',
  survey7: 'Any additional comments or feedback?',
  ans_survey1: 'Very Poor',
  ans_survey2: 'Poor',
  ans_survey3: 'Good',
  ans_survey4: 'Very Good',
  ans_survey5: 'Excellent',
  suggestion: 'Suggestions',
  sector: 'Sector / Product Category'
}
