<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-card
    flat
    :elevation="selected ? 10 : 1"
    hover
    v-bind="$attrs"
    @click="selectTime"
    v-on="$listeners"
  >
    <v-container
      class="user-select-none"
      fluid
    >
      <v-row no-gutters>
        <v-col>
          <h3 class="text-center primary--text">
            {{ item.value }}
          </h3>
        </v-col>
        <v-fab-transition>
          <v-col
            v-if="selected"
            cols="auto"
          >
            <v-icon
              color="success"
            >
              mdi-check-circle
            </v-icon>
          </v-col>
        </v-fab-transition>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

export default {
  name: 'AppointmentTimeCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    selectTime () {
      this.$emit('input', this.item.value)
    }
  }
}
</script>
