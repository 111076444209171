/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Utilities/Survey')

export default {
  ...Stub(url()),
  inquiry: form => axios.post('Static/' + url('Inquiry'), form)
}
