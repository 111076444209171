/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  datatable_search: 'Typing to search...',
  username: 'Login username',
  name: 'Your full name',
  mobile: 'Mobile number without country code',
  organization_name: 'Organization Name',
  commercial_register: 'Commercial Register number',
  vat_number: 'TAX number in your country',
  company_name: 'Your Company Name'
}
