/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  appointment: {
    new: 'موعد جديد',
    inquiry: 'استعلام عن موعد سابق / رجال الأعمال',
    inquiry_subtitle: 'قم باختيار الدولة و إدخال رقم الجوال للاستعلام عن مواعيدك مع الشركات',
    exhibitor_entry: 'دخول العارض',
    no_times_available: 'عذراً لا يوجد أوقات متاحة بهذا التاريخ',
    confirm: 'تأكيد الموعد',
    created_line: 'تم إضافة موعدك بنجاح بانتظار التأكيد شكراً لك.',
    request: 'طلب اجتماع',
    website: 'الموقع الإلكتروني',
    test_notifications: 'تجربة الإشعارات',
    statistic: 'الإحصائيات'
  },
  expo_header_line: 'برنامج تواصل رجال الأعمال:',
  today_appointments: 'مواعيد اليوم',
  tomorrow_appointments: 'مواعيد الغد',
  canceled_appointments: 'مواعيد ملغاه',
  all_appointments: 'جميع المواعيد',
  login_subtitle: 'قم باختيار الدولة وادخال رقم الجوال الخاص بك لتسجيل الدخول',
  newSurvey: 'استبيان المعرض',
  newSurvey_subtitle: 'يرجى تعبئة البيانات المطلوبة',
  survey_start_again: 'البدء من جديد',
  hosted_buyers: {
    survey: {
      1: 'ما مدى تطابق ال Hosted Buyers مع جمهورك المستهدف؟',
      2: 'هل كان ال Hosted Buyers من صناع القرار الرئيسيين في شركاتهم؟',
      3: 'هل أبدى ال  Hosted Buyerاهتمامًا حقيقيًا بمنتجاتك/خدماتك؟',
      4: 'كم تقييمك لعدد ال Hosted Buyer الذين تعتبرهم عملاء محتملين لأعمال مستقبلية؟',
      5: 'التقييم لإبرام أي صفقات تجارية أو شراكات مع ال Hosted Buyers؟',
      6: 'كيف يمكننا تحسين برنامج Hosted Buyers ليتناسب بشكل أفضل مع احتياجاتك؟',
      7: 'أي ملاحظات أو تعليقات إضافية'
    },
    title: 'استبيان تقييم العارضين',
    subtitle: 'برنامج Hosted Buyers',
    description: 'عزيزي العارض،<br>' + 'شكرًا لمشاركتك في برنامج ال Hosted Buyers. لضمان تقديم المشترين الأكثر صلة بنشاطك التجاري وتحسين تجربتك، نرجو منك تعبئة هذا الاستبيان القصير.',
    title_1: 'معلومات الشركة والاتصال:',
    title_2: 'التقييم:',
    evaluation_1: 'جودة وتطابق المشتري:',
    evaluation_2: 'نتائج الأعمال والرضا العام:',
    evaluation_3: 'الاقتراحات والتحسينات المستقبلية:',
    evaluation_typing: 'شاركنا اقتراحك...',
    thank_message: 'شكرًا لمشاركتك! ستساعدنا ملاحظاتك في تطوير وتحسين برنامج Hosted Buyer في المستقبل.'
  }
}
