/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  name: 'Settings',
  general: 'General',
  expo: 'Expo',
  new_appointment: 'New Appointment/Businessman',
  new_appointment_company: 'New Appointment/Company',
  approve_appointment: 'Approve Appointment/Businessman',
  reject_appointment: 'Reject Appointment /Businessman',
  no_appointments: 'No Appointments/Businessman',
  messages_content: 'Messages Content'
}
