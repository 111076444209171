/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  appointment: {
    new: 'New Appointment',
    inquiry: 'Appointment inquiry / business men',
    inquiry_subtitle: 'Choose the country and enter your mobile number to inquire about your appointments with companies',
    exhibitor_entry: 'Exhibitor Entry',
    no_times_available: 'Sorry, there are no available times on this date',
    confirm: 'Confirm Appointment',
    created_line: 'Your appointment has been successfully added, waiting for confirmation. Thank you.',
    request: 'Request Appointment',
    website: 'Website',
    test_notifications: 'Testing Notifications',
    statistic: 'Full Statistic'
  },
  expo_header_line: 'B2B Connect:',
  today_appointments: 'Today Appointments',
  tomorrow_appointments: 'Tomorrow Appointments',
  canceled_appointments: 'Canceled Appointments',
  all_appointments: 'All Appointments',
  login_subtitle: 'Choose the country and enter your mobile number to login',
  newSurvey: 'Exhibition Survey',
  newSurvey_subtitle: 'Please fill the required information',
  survey_start_again: 'Start Again',
  hosted_buyers: {
    survey: {
      1: 'How well did the Hosted Buyers match your target audience?',
      2: 'Were the Hosted Buyers key decision-makers in their companies?',
      3: 'Did the Hosted Buyers show genuine interest in your products/services?',
      4: 'How would you rate the number of Hosted Buyers you consider potential future clients?',
      5: 'How would you rate your success in securing business deals or partnerships through the Hosted Buyers program?',
      6: 'How can we improve the Hosted Buyers program to better meet your needs?',
      7: 'Any additional comments or feedback?'
    },
    title: 'Exhibitor Evaluation Survey',
    subtitle: 'Hosted Buyers Program',
    description: 'Dear Exhibitor,<br>Thank you for participating in the Hosted Buyers program. To ensure we connect you with the most relevant buyers and enhance your experience, please take a few moments to complete this short survey.',
    title_1: 'Company & Contact Information:',
    title_2: 'Evaluation:',
    evaluation_1: 'Buyer Quality & Relevance:',
    evaluation_2: 'Business Outcomes & Overall Satisfaction:',
    evaluation_3: 'Suggestions & Future Improvements:',
    evaluation_typing: 'Share your suggestion with us...',
    thank_message: 'Thank you for your participation! Your feedback will help us enhance the Hosted Buyers program in the future.'
  }
}
