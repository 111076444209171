/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import visitorRoutes from './visitor-routes'
import panelRoutes from './panel-routes'
import authenticationRoutes from './authentication-routes'
import { APP_ROUTES } from '@routes/list'

export default [
  {
    path: '',
    component: () => import(/* webpackChunkName: "AppLayout" */ '@layouts/LandingLayout'),
    children: [
      {
        path: '',
        // alias: '',
        name: APP_ROUTES.homePage,
        component: () => import(/* webpackChunkName: "MainPage" */ '@pages/HomePage')
      },
      {
        path: '/' + APP_ROUTES.appointment.newForm,
        name: APP_ROUTES.appointment.newForm,
        component: () => import(/* webpackChunkName: "NewAppointmentPage" */ '@pages/NewAppointment')
      },
      {
        path: '/' + APP_ROUTES.appointment.inquiry,
        name: APP_ROUTES.appointment.inquiry,
        component: () => import(/* webpackChunkName: "InquiryAppointmentPage" */ '@pages/InquiryAppointment')
      },
      // {
      //   path: '/' + APP_ROUTES.newSurvey,
      //   name: APP_ROUTES.newSurvey,
      //   component: () => import(/* webpackChunkName: "NewSurveyPage" */ '@pages/NewSurvey.vue')
      // },
      {
        path: '/' + APP_ROUTES.hostedBuyersSurvey,
        name: APP_ROUTES.hostedBuyersSurvey,
        component: () => import(/* webpackChunkName: "HostedBuyersSurveyPage" */ '@pages/HostedBuyersSurvey.vue')
      },
      ...visitorRoutes,
      ...authenticationRoutes
    ]
  },
  {
    path: '/panel',
    component: () => import(/* webpackChunkName: "AppLayout" */ '@layouts/AppLayout'),
    children: [
      ...panelRoutes
    ]
  }
]
