/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { camelCase, lowerFirst } from 'lodash'

const methods = {}

const apiMethods = require.context('./api-methods', true, /\.js$/)
apiMethods.keys().forEach((file) => {
  const name = file.split('/').pop().replace(/\.\w+$/, '').replace(/-+/, '')
  const fName = lowerFirst(camelCase(name))
  const a = apiMethods(file)
  methods[fName] = a.default || a
})
const config = {
  baseUrl: process.env.VUE_APP_API,
  name: process.env.VUE_PRODUCT_NAME,
  version: process.env.VUE_APP_VERSION
}
const Api = { methods, config }
export default Api

export {
  methods,
  config
}
