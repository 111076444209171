<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-card
    hover
    width="300"
    :disabled="disabled"
  >
    <v-card-title>{{ item.name }}</v-card-title>
    <v-img
      class="mx-auto"
      :src="item.avatar"
      contain
      max-height="100%"
      width="150"
      height="150"
    />
    <v-card-actions>
      <app-btn
        :loading="loadingBtn"
        @click="selectCompany"
      >
        {{ parseAttribute('custom.appointment.request') }}
      </app-btn>
      <v-spacer />
      <app-btn
        v-if="item.url"
        :href="item.url"
        target="_blank"
        :loading="loadingBtn"
      >
        {{ parseAttribute('custom.appointment.website') }}
      </app-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'CompanyCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: () => !1
    },
    disabled: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {

    }
  },
  computed: {
    loadingBtn: {
      get () {
        return this.loading
      },
      set (v) {
        this.$emit('update:loading', v)
      }
    }
  },
  methods: {
    selectCompany () {
      this.$emit('company', this.item)
    }
  }
}
</script>
