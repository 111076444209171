<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row>
    <v-col cols="12">
      <app-form
        ref="form"
        v-slot="{invalid}"
        :errors="errors"
      >
        <v-container
          v-if="!hideForm"
          fluid
        >
          <v-fade-transition hide-on-leave>
            <template v-if="step === 1">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <app-text-input
                      v-model="form.name"
                      cols="12"
                      name="name"
                      required
                      sm="6"
                    />
                    <app-text-input
                      v-model="form.email"
                      cols="12"
                      name="email"
                      required
                      rules="email"
                      sm="6"
                    />
                    <app-auto-select
                      v-model="form.country_id"
                      :items="countries.items"
                      :loading="countries.loading"
                      cols="12"
                      name="country_id"
                      required
                      sm="6"
                      :suffix="countryKey"
                    />
                    <app-text-input
                      v-model="form.city"
                      cols="12"
                      name="city"
                      required
                      sm="6"
                    />
                    <app-number-input
                      v-model="form.mobile"
                      cols="12"
                      name="mobile"
                      required
                      sm="6"
                      :prefix="countryKey"
                      :disabled="!form.country_id"
                      placeholder="5xxxxxxxx"
                      persistent-placeholder
                    />

                    <app-text-input
                      v-model="form.company_name"
                      cols="12"
                      name="company_name"
                      clearable
                      sm="6"
                      :hint="$t('hints.company_name')"
                      persistent-hint
                    />

                    <app-text-input
                      v-model="form.website"
                      cols="12"
                      name="website"
                      clearable
                      sm="6"
                    />
                    <app-text-input
                      v-model="form.job_title"
                      cols="12"
                      name="job_title"
                      required
                      sm="6"
                    />
                  </v-row>
                  <!--<v-row>-->
                  <!--  <app-auto-select-->
                  <!--    v-model="form.country_id"-->
                  <!--    :items="countries.items"-->
                  <!--    :loading="countries.loading"-->
                  <!--    cols="12"-->
                  <!--    name="country_id"-->
                  <!--    required-->
                  <!--    sm="6"-->
                  <!--  />-->
                  <!--  <app-text-input-->
                  <!--    v-model="form.country"-->
                  <!--    cols="12"-->
                  <!--    name="country"-->
                  <!--    required-->
                  <!--    sm="6"-->
                  <!--  />-->
                  <!--  <app-text-input-->
                  <!--    v-model="form.city"-->
                  <!--    cols="12"-->
                  <!--    name="city"-->
                  <!--    required-->
                  <!--    sm="6"-->
                  <!--  />-->
                  <!--  <app-auto-select-->
                  <!--    v-model="form.city"-->
                  <!--    :items="cities"-->
                  <!--    cols="12"-->
                  <!--    name="city"-->
                  <!--    required-->
                  <!--    sm="6"-->
                  <!--  />-->
                  <!--</v-row>-->
                </v-col>
              </v-row>
            </template>
          </v-fade-transition>
          <v-fade-transition hide-on-leave>
            <template v-if="step === 2">
              <v-row
                justify="center"
                justify-sm="center"
              >
                <v-col cols="12">
                  <h4>{{ parseAttribute('company_hint') }}</h4>
                  <div
                    v-if="companies.loading"
                    class="text-center"
                  >
                    <v-progress-circular
                      :indeterminate="companies.loading"
                      color="primary"
                    />
                  </div>
                </v-col>
                <v-col
                  v-for="(c,i) in companies.items"
                  :key="`company-${i.toString()}`"
                  cols="auto"
                  sm="auto"
                >
                  <company-card
                    :item="c"
                    :disabled="loading"
                    :loading="loadingSelectCompany"
                    @company="selectCompany"
                  />
                </v-col>
              </v-row>
            </template>
          </v-fade-transition>
          <v-fade-transition hide-on-leave>
            <template v-if="step === 3">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-container fluid>
                    <v-row>
                      <!--
                      <v-col cols="12">
                        <h4>{{ parseAttribute('sectors_hint') }}</h4>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <ValidationProvider
                          v-slot="v"
                          name="sectors"
                          rules="array_size:3|required"
                          vid="sectors"
                        >
                          <v-radio-group
                            v-model="form.sectors_id"
                            :error-messages="v.errors"
                            multiple
                            column
                          >
                            <v-row dense>
                              <v-checkbox
                                v-for="(e,i) in sectors"
                                :key="i.toString()"
                                :value="e.value"
                                :label="e.text"
                                class="col-sm-6"
                              />
                            </v-row>
                          </v-radio-group>
                        </ValidationProvider>
                      </v-col>-->
                      <app-checkbox-group
                        v-model="form.sectors_id"
                        :label="parseAttribute('sectors_hint')"
                        :has-select-all="!1"
                        :items="sectors"
                        cols="12"
                        multiple
                        name="sectors_id"
                        required
                        rules="array_size:3"
                      />
                      <app-sectors
                        v-model="form.sectors_id"
                        class="d-none"
                        cols="12"
                        multiple
                        name="sectors_id"
                        required
                        rules="array_size:3"
                        @set-items="sectors = $event"
                      />
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <h4>{{ parseAttribute('meeting_purpose_hint') }}</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-divider />
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group
                          v-model="form.meeting_purpose_id"
                          hide-details="auto"
                        >
                          <v-radio
                            v-for="(e,i) in meetingPurposes"
                            :key="i.toString()"
                            :value="e.value"
                            :label="e.text"
                          />
                        </v-radio-group>
                      </v-col>
                      <!--                  <app-checkbox-group
                        v-model="form.meeting_purpose_id"
                        :label="parseAttribute('meeting_purpose_hint')"
                        :has-select-all="!1"
                        cols="12"
                        name="meeting_purpose_id"
                        required
                        :items="meetingPurposes"
                      />-->
                      <app-meeting-purposes
                        v-model="form.meeting_purpose_id"
                        cols="12"
                        name="meeting_purpose_id"
                        required
                        class="d-none"
                        @set-items="meetingPurposes = $event"
                      />
                    </v-row>
                    <v-row
                      v-if="showOtherMeetingPurposes"
                    >
                      <app-text-input
                        v-model="form.other_meeting_purpose"
                        cols="12"
                        name="other_meeting_purpose"
                        required
                        sm="6"
                      />
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-fade-transition>
          <v-fade-transition hide-on-leave>
            <template v-if="step === 4">
              <v-row no-gutters>
                <v-container
                  fluid
                  class="pa-0"
                >
                  <app-text-input
                    :value="form.date"
                    class="d-none"
                    cols="12"
                    name="date"
                    required
                    sm="6"
                    type="hidden"
                  />
                  <v-row
                    justify="center"
                    justify-sm="start"
                  >
                    <v-col cols="auto">
                      <v-row dense>
                        <v-col cols="auto">
                          <span class="title">
                            {{ parseAttribute('appointment_date_id') }}: {{ form.date }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="auto">
                          <slide-fade-transition>
                            <v-sheet
                              v-if="appointmentDates.loading"
                              max-width="300"
                              elevation="6"
                            >
                              <v-skeleton-loader
                                type="list-item, divider, date-picker-options, date-picker-days"
                              />
                            </v-sheet>
                            <v-date-picker
                              v-else
                              v-model="form.date"
                              :show-current="!1"
                              elevation="6"
                              :allowed-dates="allowedDates"
                              :min="minDate"
                              :max="maxDate"
                              color="primary"
                              :disabled="loading"
                            />
                          </slide-fade-transition>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-show="form.date"
                      cols="auto"
                    >
                      <v-row
                        dense
                        justify="space-between"
                      >
                        <v-col cols="auto">
                          <span class="title">
                            {{ parseAttribute('appointment_time_id') }}: {{ form.time }}
                          </span>
                        </v-col>
                        <v-col
                          v-if="appointmentTimes.loading"
                          cols="auto"
                        >
                          <v-progress-circular
                            indeterminate
                            class="text-center"
                            color="primary"
                            size="20"
                          />
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="auto">
                          <v-sheet
                            ref="appointmentTimes"
                            rounded
                            width="300"
                            max-width="100%"
                            height="378"
                            elevation="6"
                            class="overflow-auto"
                          >
                            <v-skeleton-loader
                              v-if="appointmentTimes.loading || appointmentDates.loading"
                              type="date-picker"
                            />
                            <v-col
                              v-if="form.date"
                              cols="12"
                            >
                              <slide-fade-transition>
                                <v-row
                                  v-if="showSelectAppointmentTimes"
                                  justify="center"
                                >
                                  <v-col
                                    v-for="(c,i) in appointmentTimes.items"
                                    :key="`appointment-time-${i.toString()}`"
                                    cols="12"
                                  >
                                    <appointment-time-card
                                      v-model="form.time"
                                      :item="c"
                                      :selected="form.time === c.value"
                                      :disabled="loading"
                                    />
                                  </v-col>
                                </v-row>
                              </slide-fade-transition>
                              <slide-fade-transition>
                                <v-row v-if="showNoTimeText">
                                  <v-col
                                    cols="12"
                                    align-self="center"
                                  >
                                    <h4>{{ AppSettings[`no_appointments_text_${AppLocale}`] }}</h4>
                                  </v-col>
                                </v-row>
                              </slide-fade-transition>
                            </v-col>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </template>
          </v-fade-transition>
          <slide-fade-transition>
            <v-row justify="center">
              <v-col cols="auto">
                <app-btn
                  :disabled="!(step > 1) || loading"
                  @click="prevStep"
                >
                  <v-icon left>
                    {{ AppRtl ? 'arrow_forward_ios' : 'arrow_back_ios' }}
                  </v-icon>
                  {{ $t('prev') }}
                </app-btn>
              </v-col>
              <v-col cols="auto">
                <app-btn
                  :disabled="invalid || disableNextButton"
                  :loading="loading"
                  @click="nextStep"
                >
                  {{ lastStep ? $t('custom.appointment.confirm') : $t('next') }}
                  <v-icon
                    v-if="!lastStep"
                    right
                  >
                    {{ !AppRtl ? 'arrow_forward_ios' : 'arrow_back_ios' }}
                  </v-icon>
                  <v-icon
                    v-else
                    right
                  >
                    event_available
                  </v-icon>
                </app-btn>
              </v-col>
            </v-row>
          </slide-fade-transition>
        </v-container>
        <v-container v-else>
          <v-row justify="center">
            <v-col
              cols="auto"
              class="text-center"
            >
              <v-icon
                color="success"
                class="mb-2"
                size="50"
              >
                task_alt
              </v-icon>
              <h3 class="text-center success--text">
                {{ $t('custom.appointment.created_line') }}
              </h3>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <app-btn
                @click="onNewAppointment"
              >
                {{ $t('custom.appointment.new') }}
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </app-form>
    </v-col>
  </v-row>
</template>

<script>
import SlideFadeTransition from '@components/SlideFadeTransition'
import CompanyCard from '@components/company/CompanyCard'
import AppointmentTimeCard from '@components/appointment/AppointmentTimeCard'
import { isDevelopment } from '@helpers/Tools'

const events = {
  updateForm: 'update:item',
  submit: 'submit'
}

export default {
  name: 'AppointmentForm',
  components: { AppointmentTimeCard, CompanyCard, SlideFadeTransition },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      hideForm: !1,
      step: 1,
      steps: 4,
      loading: !1,
      loadingSelectCompany: !1,
      errors: {},
      form: {
        name: isDevelopment ? 'Ahmed Faiz' : null,
        email: isDevelopment ? '4mythapp@gmail.com' : null,
        mobile: isDevelopment ? '590470092' : null,
        job_title: isDevelopment ? 'CTO' : null,
        company_name: isDevelopment ? 'MyTh' : null,
        website: isDevelopment ? 'www.4myth.com' : null,
        country_id: null,
        city: isDevelopment ? 'Riyadh' : null,
        company_id: null,
        date: null,
        time: null,
        sectors_id: [],
        meeting_purpose_id: null,
        locale: this.AppLocale || 'ar'
      },
      sectors: [],
      meetingPurposes: [],
      countries: {
        items: [],
        loading: !1
      },
      companies: {
        items: [],
        loading: !1
      },
      appointmentDates: {
        items: [],
        loading: !1
      },
      appointmentTimes: {
        items: [],
        loading: !1
      }
    }
  },
  computed: {
    // step:{
    //   set(v){
    //     console.log(v)
    //   },
    //   get(){
    //
    //   }
    // },
    cities () {
      return this.countries.items.find(e => e.id === this.form.country_id)?.cities || []
    },
    city () {
      return this.cities.find(e => e.id === this.form.city_id)
    },
    disableNextButton () {
      if (this.step === 2) {
        return !0
      }

      if (this.step === 4) {
        return !this.form.date || !this.form.time
      }
      return !1
    },
    showNoTimeText () {
      return this.form.date && !this.appointmentTimes.loading && !this.appointmentDates.loading && !this.appointmentTimes.items.length
    },
    showSelectAppointmentTimes () {
      return this.form.date && this.appointmentTimes.items.length && !this.appointmentTimes.loading
    },
    showOtherMeetingPurposes () {
      if (!this.form.meeting_purpose_id) {
        return !1
      }
      return this.meetingPurposes.find(e => e.id === this.form.meeting_purpose_id)?.other === !0
    },
    lastStep () {
      return this.step === this.steps
    },
    countryKey () {
      return this.countries.items.find(c => c.id === this.form.country_id)?.key
    },
    minDate () {
      const i = [...this.appointmentDates.items].slice(0)
      return i.length ? i[0].date : undefined
    },
    maxDate () {
      const i = [...this.appointmentDates.items].slice(-1)
      return i.length ? i[0].date : undefined
    }
  },
  watch: {
    'form.date': 'watchAppointmentDate',
    'form.meeting_purpose_id': 'watchMeetingPurpose'
    // step (step) {
    //   const current = this.$route
    //   if (current.query?.step > this.steps) {
    //     this.$router.replace({ ...current, query: { ...(current.query || {}), step: 1 } })
    //     return
    //   }
    //   if (step !== parseInt(current.query?.step || 0)) {
    //     this.$router.push({ ...current, query: { ...(current.query || {}), step } })
    //   }
    // },
    // '$route.query.step' (step) {
    //   console.log(step)
    //   // step = step || 1
    //   // if (step > this.steps) {
    //   //   step = 1
    //   // }
    //   // this.step = step
    // }
  },
  beforeMount () {
    this.form = { ...this.form, ...this.item }
  },
  mounted () {
    this.fetchCountries()
    this.fetchCompanies()
    this.fetchAppointmentDates()
    // this.initFirstStep()
  },
  beforeDestroy () {
    // window.removeEventListener('popstate', this.registerBeforeUnloadPage, !1)
  },
  methods: {
    prevStep () {
      if (this.step === 3) {
        // this.updateForm({ meeting_purpose_id: undefined, other_meeting_purpose: undefined, sectors_id: [] })
      }
      if (this.step === 4) {
        this.updateForm({ date: undefined, time: undefined })
      }
      this.$nextTick(() => {
        --this.step
      })
    },
    async nextStep () {
      // # create temp appointment
      if (!this.form.id) {
        let success = !1
        this.loading = !0
        try {
          const { _data, _success } = await this.apiService.appointment.init({ ...this.form, locale: this.AppLocale })
          this.updateForm(_data)
          success = _success === !0
        } catch (e) {
          const { _message } = e || {}
          _message && this.alertError(_message)
        } finally {
          this.loading = !1
          if (success) {
            this.setDefaultFormDate()
            ++this.step
          }
        }
        return
      }
      let success = !1
      this.loading = !0
      try {
        const { _data, _success } = await this.apiService.appointment.fill(this.form.id, { ...this.form, locale: this.AppLocale, lastStep: this.lastStep })
        success = _success === !0
        if (success) {
          (this.step !== this.steps && _data) && this.updateForm(_data)
        }
      } catch (e) {
        const { _message } = e || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
        if (success) {
          if (this.step === this.steps) {
            this.hideForm = !0
          } else {
            if (this.appointmentDates.items.length && !this.form.date) {
              this.setDefaultFormDate()
            }
            this.$nextTick(() => (++this.step))
          }
        }
      }
    },
    setDefaultFormDate () {
      !this.form.date && this.$nextTick(() => { this.form.date = this.appointmentDates.items[0].date })
    },
    updateForm (v, old, emit = !1) {
      this.form = { ...this.form, ...v }
      emit === !0 && this.$nextTick(() => this.$emit(events.updateForm, this.form))
    },
    async selectCompany (company) {
      this.form.company_id = company.id
      this.$nextTick(() => this.nextStep())
    },
    async fetchCountries () {
      if (this.countries.loading) {
        return
      }
      this.countries.loading = !0
      this.countries.items = []
      try {
        const { _data } = await this.apiService.country.staticUtilities({ itemsPerPage: -1, withRequest: 'cities' })
        this.countries.items = _data || []
        if (this.countries.items[0]) {
          this.$nextTick(() => {
            this.form.country_id = this.countries.items[0].id
          })
        }
      } catch (e) {

      } finally {
        this.countries.loading = !1
      }
    },
    async fetchCompanies () {
      if (this.companies.loading) {
        return
      }
      this.companies.loading = !0
      this.companies.items = []
      try {
        const { _data } = await this.apiService.company.staticUtilities({ itemsPerPage: -1 })
        this.companies.items = _data || []
      } catch (e) {

      } finally {
        this.companies.loading = !1
      }
    },
    async fetchAppointmentDates () {
      if (this.appointmentDates.loading) {
        return
      }
      this.appointmentDates.loading = !0
      this.appointmentDates.items = []
      try {
        const { _data } = await this.apiService.appointmentDate.staticUtilities({ itemsPerPage: -1 })
        this.appointmentDates.items = _data || []
      } catch (e) {

      } finally {
        this.appointmentDates.loading = !1
        this.setDefaultFormDate()
      }
    },
    watchAppointmentDate (newVal, oldVal) {
      // console.log('watchAppointmentDate')
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.updateForm({ time: undefined })
          this.$nextTick(() => {
            this.fetchAppointmentTimes()
          })
        })
      }
    },
    watchMeetingPurpose (newVal, oldVal) {
      this.form.other_meeting_purpose = undefined
    },
    async fetchAppointmentTimes () {
      // console.log(this.form.date)
      if (this.appointmentTimes.loading || !this.form.date || !this.form.company_id) {
        return
      }
      this.appointmentTimes.loading = !0
      this.appointmentTimes.items = []
      try {
        const { _data } = await this.apiService.appointmentDate.availableTimesOfCompany(this.form.company_id, this.form.date)
        this.appointmentTimes.items = _data || []
        // this.$nextTick(() => this.$helpers.goTo(this.$refs.appointmentTimes))
      } catch (e) {

      } finally {
        this.appointmentTimes.loading = !1
      }
    },
    allowedDates (val) {
      // console.log(val, this.appointmentDates.items)
      return this.appointmentDates.items.find(e => e.date === val)
      // return parseInt(val.split('-')[2], 10) % 1 === 0
    },
    initFirstStep () {
      this.$nextTick(() => {
        const step = parseInt(this.$route.query?.step || 0)

        if (step === 0 && this.step === 0) {
          console.log('===')
          ++this.step
          return
        }
        if (step > this.step) {
          console.log('>>>')
          this.step = 1
        }
      })
    },
    onNewAppointment () {
      this.companies.items = this.companies.items.filter(e => e.id !== this.form.company_id)

      this.form = {
        name: this.form.name,
        email: this.form.email,
        mobile: this.form.mobile,
        company_name: this.form.company_name,
        website: this.form.website,
        job_title: this.form.job_title,
        country_id: this.form.country_id,
        city: this.form.city,
        locale: this.form.locale,
        company_id: null,
        date: null,
        time: null,
        sectors_id: [],
        meeting_purpose_id: null
      }
      this.step = 1
      this.hideForm = !1
      this.$nextTick(() => {
        this.nextStep()
      })
    }
  }
}
</script>
