/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  user_id: 'المستخدم',
  role_id: 'الوظيفة',
  roles_id: 'الوظائف',
  permission_id: 'الصلاحية',
  permissions_id: 'الصلاحيات',
  country_id: 'الدولة',
  countries_id: 'الدول',
  city_id: 'المدينة',
  cities_id: 'المدن',
  order_id: 'الطلب',
  orders_id: 'الطلبات',
  order_status_id: 'حالة الطلب',
  order_statuses_id: 'حالات الطلب',
  category_id: 'القسم',
  categories_id: 'الأقسام',
  id: 'ID',
  login_id: 'حقل الدخول',
  username: 'اسم المستخدم',
  name: 'الاسم',
  name_ar: 'الاسم بالعربية',
  name_en: 'الاسم بالانجليزية',
  password: 'كلمة المرور',
  password_confirmation: 'تأكيد كلمة المرور',
  mobile: 'رقم الجوال',
  email: 'البريد الإلكتروني',
  locale: 'اللغة',
  active: 'فعال',
  code: 'الكود',
  send_time: 'وقت الإرسال',
  token: 'الرمز',
  read_at: 'وقت القراءة',
  key: 'المفتاح',
  order_by: 'ترتيب العرض',
  app_name: 'اسم الموقع',
  app_name_ar: 'اسم البرنامج بالعربية',
  app_name_en: 'اسم البرنامج بالإنجليزية',
  description: 'الوصف',
  description_ar: 'الوصف بالعربية',
  description_en: 'الوصف بالانجليزية',
  job_title: 'المسمى الوظيفي',
  company: 'الشركة',
  company_id: 'الشركة العارضة',
  country: 'الدولة',
  city: 'المدينة',
  phone: 'رقم الهاتف',
  whatsapp: 'Whatsapp',
  two_factor_auth: 'التحقق بخطوتين',
  time: 'الوقت',
  from_time: 'الوقت من',
  to_time: 'الوقت إلى',
  date: 'التاريخ',
  full_date: 'التاريخ كامل',
  from_date: 'التاريخ من',
  to_date: 'التاريخ إلى',
  type: 'النوع',
  status: 'الحالة',
  push_token: 'رمز الجهاز',
  verification_code: 'رمز التحقق',
  attachment: 'المرفق',
  attachments: 'المرفقات',
  notification_email: 'بريد الإشعارات',
  notification_mobile: 'جوال الإشعارات',
  web: 'رابط الموقع',
  website: 'الموقع الإلكتروني',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'بريد خدمة العملاء',
  customer_service_mobile: 'جوال خدمة العملاء',
  customer_service_phone: 'هاتف خدمة العملاء',
  google_map_url: 'رابط جوجل ماب',
  app_store_url: 'رابط التطبيق App Store',
  play_store_url: 'رابط التطبيق Play Store',
  address: 'العنوان',
  address_ar: 'العنوان بالعربية',
  address_en: 'العنوان بالإنجليزية',
  contact_us: 'تواصل معنا',
  contact_us_ar: 'تواصل معنا بالعربية',
  contact_us_en: 'تواصل معنا بالانجليزية',
  about_us: 'من نحن',
  about_us_ar: 'من نحن بالعربية',
  about_us_en: 'من نحن بالانجليزية',
  privacy_policy: 'سياسة الخصوصية',
  privacy_policy_ar: 'سياسة الخصوصية بالعربية',
  privacy_policy_en: 'سياسة الخصوصية بالانجليزية',
  terms_and_conditions: 'الشروط والأحكام',
  terms_and_conditions_ar: 'الشروط والأحكام بالعربية',
  terms_and_conditions_en: 'الشروط والأحكام بالانجليزية',
  commercial_register: 'السجل التجاري',
  commercial_name: 'الاسم التجاري',
  commercial_name_ar: 'الاسم التجاري بالعربية',
  commercial_name_en: 'الاسم التجاري بالإنجليزية',
  vat_number: 'الرقم الضريبي',
  notification_methods: 'طرق الإشعارات',
  avatar: 'الصورة',
  blobAvatar: 'الصورة',
  created_at: 'تاريخ الإضافة',
  from_created_at: 'تاريخ الإضافة من',
  to_created_at: 'تاريخ الإضافة إلى',
  message: 'الرسالة',
  price: 'السعر',
  notes: 'ملاحظات',
  icon: 'الأيقونة',
  subject: 'العنوان',
  image: 'الصورة',
  images: 'الصور',
  choose_image: 'اختر صورة',
  update_image: 'تعديل الصورة',
  url: 'الرابط',
  amount: 'القيمة',
  discount: 'الخصم',
  discount_type: 'نوع الخصم',
  discount_amount: 'قيمة الخصم',
  meta: 'بيانات',
  quantity: 'الكمية',
  location: 'الموقع',
  tax: 'الضريبة',
  new_order_status: 'حالة الطلب الجديد',
  pending_order_status: 'حالة الطلب المعلق',
  accepted_order_status: 'حالة الطلب المقبول',
  completed_order_status: 'حالة الطلب المكتمل',
  canceled_order_status: 'حالة الطلب الملغي',
  fixed_app_fees: 'رسوم التطبيق',
  percent_app_fees: 'نسبة التطبيق',
  payable_id: 'رقم المرجع',
  payable_type: 'نوع المرجع',
  total: 'المجموع',
  data: 'البيانات',
  from_discount_amount: 'قيمة الخصم من',
  to_discount_amount: 'قيمة الخصم إلى',
  expire_date: 'تاريخ الانتهاء',
  from_expire_date: 'تاريخ الانتهاء من',
  to_expire_date: 'تاريخ الانتهاء الى',
  read: 'تم القراءة',
  ref_key: 'الرقم المرجعي',
  attachment_type: 'نوع المرفق',
  percentage: 'النسبة',
  vat: 'الضريبة',
  vat_amount: 'قيمة الضريبة',
  payment_date: 'تاريخ الدفعة',
  from_payment_date: 'تاريخ الدفعة من',
  to_payment_date: 'تاريخ الدفعة إلى',
  payment_type: 'نوع الدفعة',
  paid_amount: 'المبلغ المدفوع',
  color: 'اللون',
  symbol: 'الرمز',
  contact: 'التواصل',
  start_date: 'تاريخ البداية',
  from_start_date: 'تاريخ البداية من',
  to_start_date: 'تاريخ البداية إلى',
  end_date: 'تاريخ النهاية',
  from_end_date: 'تاريخ النهاية من',
  to_end_date: 'تاريخ النهاية إلى',
  start_time: 'وقت البداية',
  from_start_time: 'وقت البداية من',
  to_start_time: 'وقت البداية إلى',
  end_time: 'وقت النهاية',
  from_end_time: 'وقت النهاية من',
  to_end_time: 'وقت النهاية إلى',
  database: 'البرنامج',
  mail: 'البريد الإلكتروني',
  sms: 'الرسائل النصية',
  pay_date: 'تاريخ الدفع',
  from_pay_date: 'تاريخ الدفع من',
  to_pay_date: 'تاريخ الدفع إلى',
  due_amount: 'المبلغ المستحق',
  customer_name: 'اسم العميل',
  signature: 'التوقيع',
  stamp: 'الختم',
  other: 'أخرى',
  sectors: 'القطاعات',
  sector_id: 'القطاع',
  sectors_id: 'القطاعات',
  sectors_hint: 'ما هي القطاعات التي تهمك: (3 خيارات على الأكثر)',
  company_hint: 'اسم الشركة المطلوب الاجتماع معها:',
  meeting_purpose_id: 'الغرض من الاجتماع',
  meeting_purposes_id: 'الغرض من الاجتماع',
  other_meeting_purpose: 'الغرض من الاجتماع',
  meeting_purpose_hint: 'الغرض من الاجتماع:',
  appointment_date_id: 'تاريخ الموعد',
  appointment_date_hint: 'حدد تاريخ الموعد:',
  appointment_time_id: 'وقت الموعد',
  appointment_time_hint: 'حدد وقت الموعد:',
  start_hour: 'ساعة البداية',
  end_hour: 'ساعة النهاية',
  appointment_id: 'الموعد',
  appointments_id: 'المواعيد',
  companies_id: 'الشركات',
  company_name: 'اسم الشركة',
  available_appointments: 'المواعيد المتاحة',
  approved_appointment: 'تأكيد الموعد',
  reject_appointment: 'الاعتذار عن الموعد',
  pending_appointments_period: 'دقائق المواعيد في الانتظار',
  new_appointment_text: 'إضافة موعد رجال الأعمال',
  new_appointment_text_ar: 'إضافة موعد رجال الأعمال بالعربية',
  new_appointment_text_en: 'إضافة موعد رجال الأعمال بالإنجليزية',
  company_new_appointment_text: 'إضافة موعد الشركة العارضة',
  company_new_appointment_text_ar: 'إضافة موعد الشركة العارضة بالعربية',
  company_new_appointment_text_en: 'إضافة موعد الشركة العارضة بالإنجليزية',
  approve_appointment_text: 'تأكيد موعد رجال الأعمال',
  approve_appointment_text_ar: 'تأكيد موعد رجال الأعمال بالعربية',
  approve_appointment_text_en: 'تأكيد موعد رجال الأعمال بالإنجليزية',
  reject_appointment_text: 'اعتذار موعد رجال الأعمال',
  reject_appointment_text_ar: 'اعتذار موعد رجال الأعمال بالعربية',
  reject_appointment_text_en: 'اعتذار موعد رجال الأعمال بالإنجليزية',
  no_appointments_text: 'لا توجد مواعيد رجال الأعمال',
  no_appointments_text_ar: 'لا توجد مواعيد رجال الأعمال بالعربية',
  no_appointments_text_en: 'لا توجد مواعيد رجال الأعمال بالإنجليزية',
  stand: 'استاند',
  expo_header: 'هيدر المعرض',
  expo_header_image: 'هيدر المعرض',
  expo_header_blob: 'هيدر المعرض',
  default_password: 'كلمة المرور الافتراضية',
  c_name: 'اسم مسؤول الشركة العارضة',
  c_company: 'اسم الشركة العارضة',
  c_mobile: 'جوال الشركة العارضة',
  c_email: 'بريد الشركة العارضة',
  c_stand: 'ستاند الشركة العارضة',
  b_name: 'اسم رجل الأعمال',
  b_company: 'شركة رجل الأعمال',
  b_mobile: 'جوال رجل الأعمال',
  b_email: 'بريد رجل الأعمال',
  b_country: 'دولة رجل الأعمال',
  b_website: 'الموقع الإلكتروني لرجل الأعمال',
  meeting_purpose: 'الغرض من الاجتماع',
  company_rejected_success_text: 'اعتذار الشركة العارضة',
  company_rejected_success_text_ar: 'اعتذار الشركة العارضة بالعربية',
  company_rejected_success_text_en: 'اعتذار الشركة العارضة بالإنجليزية',
  company_approved_success_text: 'موافقة الشركة العارضة',
  company_approved_success_text_ar: 'موافقة الشركة العارضة بالعربية',
  company_approved_success_text_en: 'موافقة الشركة العارضة بالإنجليزية',
  appointment_minutes: 'دقائق الموعد',
  survey1: 'عدد اجتماعات العمل',
  survey2: 'عدد الزائرين الى منصة الشركة',
  survey3: 'نوعية الزائرين الى منصة الشركة',
  survey4: 'تحقيق اهداف الاشتراك في المعرض',
  survey5: 'توقيع عقود او اتفاقيات',
  survey6: 'تنظيم المعرض بشكل عام',
  survey7: 'هل ترغب في الاشتراك في المعرض السنة القادمة؟',
  survey8: 'هل تؤيد دخول الأطفال الى المعرض ؟',
  ans_survey1: 'غير راض',
  ans_survey2: 'راض الى حد ما',
  ans_survey3: 'راض',
  ans_survey4: 'راض بشكل متوسط',
  ans_survey5: 'راض بشكل كبير',
  suggestion: 'اقتراحات'
}
