/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { mapActions } from 'vuex'
import Auth from '@helpers/Auth'
import Config from '@app/config'
import { disableHtmlSelect } from '@helpers/Tools'
import { NotificationsMixin } from '@/mixins/index'

export default {
  metaInfo () {
    const n = this.RootUnreadNotifications
    const title = (n ? `(${n}) ` : '') + (this.AppName || '')
    const description = this.AppDescription || ''

    return {
      title,
      titleTemplate: c => c && c !== title ? `${title} - ${c}` : title,
      meta: [
        {
          name: 'description',
          content: description
        }
      ]
    }
  },
  data () {
    return {
      RootCurrency: { ...Config.currency.value },
      RootTransitionName: 'slide-right',
      RootProgressBar: false,
      RootShowAxiosErrorMessage: !1,
      RootSettings: {},
      RootNotifications: [],
      RootUnreadNotifications: 0,
      RootNotificationsInterval: null,
      FetchedRootNotifications: !1,
      FetchingRootNotifications: !1
    }
  },
  mixins: [NotificationsMixin],
  methods: {
    ...mapActions('auth', ['iniAuthFromStorage', 'refreshUser', 'setAppReady']),
    updateProfile (user = null) {
      if (user) {
        return new Promise((resolve) => {
          this.authUser = user
          resolve(user)
          return user
        })
      } else {
        return this.apiService.user.refreshProfile().then(({ _data, _success }) => {
          if (_success === true) {
            this.authUser = _data
            return _data
          }
          return {}
        })
      }
    },
    iniTheme () {
      let v = JSON.parse(localStorage.getItem(Config.theme.storageKey))
      if (v) {
        v = v.value
      } else {
        try {
          v = window.matchMedia('(prefers-color-scheme: dark)').matches
        } catch (e) {
          v = Config.theme.dark
        }
      }
      this.themeDark = v
    },
    async prepare () {
      this.IniAppLocale()
      this.iniTheme()
      this.IniAppCurrency()

      await this.iniAuthFromStorage(() => {
        this.$nextTick(async () => {
          if (this.authUser?.locale) {
            // console.log(this.AppReady)
            this.AppLocale = this.authUser.locale
          }
        })
      })

      this.$nextTick(async () => {
        try {
          await this.fetchAppSettings(() => {
            this.setAppReady(!0)
          })
        } catch (e) {
        }
      })
    }
  },
  beforeCreate () {
    this.$axios.interceptors.response.use((response) => response, (error) => {
      const res = () => Promise.reject(error)
      if (!error || !error.response) {
        return res()
      }

      if (error.response.status === 403) {
        try {
          (async () => {
            const name = Auth.isLogin() ? this.APP_ROUTES.user.home : this.APP_ROUTES.homePage
            if (this.isRoute(name)) {
              window.location.href = '/'
            } else {
              const r = this.$router.resolve(this.route(name))
              window.location.href = r.resolved.fullPath
              // await this.$router.replace(this.route(name))
            }
          })()
        } catch (e) {
          // console.log(e)
          window.location.href = '/'
        }
        return res()
      }

      if (this.RootShowAxiosErrorMessage === !0 && error.response.status === 422 && error._message) {
        this.$nextTick(() => this.alertError(error._message))
      }

      return res()
    })
  },
  async beforeMount () {
    try {
      await this.prepare()
    } catch (e) {
      console.log(e)
    }
  },
  watch: {
    authUser (v) {
      if (v?.id) {
        this.initAppNotifications()
      } else {
        this.clearAppNotifications()
      }
      // console.log(v)
    }
    // '$route' (to, from) {
    //   const toDepth = to?.path.split('/').length || 0
    //   const fromDepth = from?.path.split('/').length || 0
    //   this.RootTransitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    //   // console.log(this.RootTransitionName)
    // }
  },
  mounted () {
    if (this.IsMobileApp) {
      disableHtmlSelect()
    }
  }
}
