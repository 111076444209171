<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row dense>
    <v-col
      cols="12"
    >
      <v-container fluid>
        <app-row align="center">
          <app-col
            class="justify-content-centers"
            cols="12"
            sm="6"
          >
            <h2 class="mb-3">
              {{ parseAttribute('image') }}
            </h2>
            <v-fade-transition>
              <v-avatar
                v-if="blobAvatar"
                :color="avatarColor"
                size="170"
              >
                <v-img
                  :src="blobAvatar"
                  contain
                />
              </v-avatar>
            </v-fade-transition>
          </app-col>
        </app-row>
        <app-row>
          <app-file-input
            :label="label"
            :value="value"
            accept="image/x-png,image/gif,image/jpeg"
            clearable
            cols="12"
            sm="6"
            name="image"
            v-bind="$attrs"
            v-on="$listeners"
          />
        </app-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'InputAvatar',
  props: {
    value: {},
    blobAvatar: {},
    label: {}
  }
}
</script>
