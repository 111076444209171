/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  appointment: {
    new: 'موعد جديد',
    inquiry: 'استعلام عن موعد سابق / رجال الأعمال',
    inquiry_subtitle: 'قم باختيار الدولة و إدخال رقم الجوال للاستعلام عن مواعيدك مع الشركات',
    exhibitor_entry: 'دخول العارض',
    no_times_available: 'عذراً لا يوجد أوقات متاحة بهذا التاريخ',
    confirm: 'تأكيد الموعد',
    created_line: 'تم إضافة موعدك بنجاح بانتظار التأكيد شكراً لك.',
    request: 'طلب اجتماع',
    website: 'الموقع الإلكتروني',
    test_notifications: 'تجربة الإشعارات',
    statistic: 'الإحصائيات'
  },
  expo_header_line: 'برنامج تواصل رجال الأعمال:',
  today_appointments: 'مواعيد اليوم',
  tomorrow_appointments: 'مواعيد الغد',
  canceled_appointments: 'مواعيد ملغاه',
  all_appointments: 'جميع المواعيد',
  login_subtitle: 'قم باختيار الدولة وادخال رقم الجوال الخاص بك لتسجيل الدخول',
  newSurvey: 'استبيان المعرض',
  newSurvey_subtitle: 'يرجى تعبئة البيانات المطلوبة',
  survey_start_again: 'البدء من جديد'
}
