/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  table_headers: 'Table Headers',
  clear_filter: 'Clear Filter',
  options: 'Options',
  error_export: 'An error occurred while exporting data, please try again later'
}
