<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :form.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      excel
      @submitClickForm="submitClickForm"
    >
      <!--<template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          :disabled="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
      </template>-->
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'

export default {
  name: 'Surveys',
  helperApiName: 'survey',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Utilities.Survey',
      headers: [
        'company_name',
        'survey1_to_string',
        'survey2_to_string',
        'survey3_to_string',
        'survey4_to_string',
        'survey5_to_string',
        'survey6_to_string',
        'survey7_to_string',
        'survey8_to_string',
        'suggestion',
        'created_at_to_string'
      ]
    }
  },
  mounted () {
    // this.checkPermission('index')
  }
}
</script>
