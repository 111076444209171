/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  table_headers: 'حقول العرض',
  clear_filter: 'إلغاء التصفية',
  options: 'خيارات',
  error_export: 'حدث خطأ أثناء تصدير البيانات، الرجاء المحاولة لاحقاً'
}
