/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Appointment')
const Static = StubUrl(`Static/${url()}`)

export default {
  ...Stub(url()),
  init: form => axios.post(Static('Init'), form),
  fill: (id, form) => axios.post(Static(`${id}/Fill`), form),
  inquiry: form => axios.post(Static('Inquiry'), form),
  statuses: () => axios.get(Static('Statuses')),
  approve: id => axios.get(url(`${id}/Approve`)),
  reject: id => axios.get(url(`${id}/Reject`)),
  pending: id => axios.get(url(`${id}/Pending`)),
  sendAllNotifications: id => axios.get(url(`${id}/SendAllNotifications`)),
  destroyAll: ids => axios.post(url('DestroyAll'), { ids }),
  replacedKeys: () => axios.get(url('ReplacedKeys')),
  appointmentsTimes: () => axios.get(Static('AppointmentsTimes')),
  fullStatistic (params = {}, config = {}) {
    const u = url('FullStatistic')
    return params === !0 ? u : axios.get(u, { ...config, params })
  }
}
