<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title>
              {{ $t('statistics') }}
            </v-toolbar-title>
            <v-spacer class="d-print-none" />
            <app-btn
              :icon="AppIsSmall"
              class="me-1 d-print-none"
              @click="openDialog()"
            >
              <v-icon
                v-if="AppIsSmall"
              >
                filter_alt
              </v-icon>
              <span v-if="!AppIsSmall">{{ $t('filter') }}</span>
            </app-btn>
            <!--<v-btn
              class="me-1 d-print-none"
              icon
              @click="printReport()"
            >
              <v-icon>print</v-icon>
            </v-btn>-->
            <!--            <v-btn
              class="me-1 d-print-none"
              icon
              @click="exportExcel()"
            >
              <v-icon>
                description
              </v-icon>
            </v-btn>-->
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row
        v-if="dates.length>0"
        dense
      >
        <slide-fade-transition-group class="d-flex col-auto">
          <v-col
            v-for="(e,i) in dates"
            :key="i.toString()"
            cols="auto"
          >
            <app-btn
              :disabled="form.from_date === e.date && form.to_date === e.date "
              @click="setDate(e)"
            >
              {{ e.date }}
            </app-btn>
          </v-col>
        </slide-fade-transition-group>
        <slide-fade-transition>
          <v-col
            v-if="form.from_date || form.to_date"
            cols="auto"
            align-self="center"
          >
            <app-btn @click="clearDates">
              {{ $t('show_all') }}
            </app-btn>
          </v-col>
        </slide-fade-transition>
      </v-row>
      <v-row dense>
        <v-col
          class="d-print-flex mt-2"
          cols="12"
        >
          <v-row>
            <v-col
              v-if="form.from_date"
              cols="12"
              sm="6"
            >
              <span class="font-weight-bold">{{ parseAttribute('from_date') }}:&nbsp;</span>
              <span>{{ form.from_date }}</span>
              <v-divider class="my-2" />
            </v-col>

            <v-col
              v-if="form.to_date"
              cols="12"
              sm="6"
            >
              <span class="font-weight-bold">{{ parseAttribute('to_date') }}:&nbsp;</span>
              <span>{{ form.to_date }}</span>
              <v-divider class="my-2" />
            </v-col>

            <v-col
              v-if="companyName"
              cols="12"
              sm="6"
            >
              <span class="font-weight-bold">{{ parseAttribute('company_id') }}:&nbsp;</span>
              <span>{{ companyName }}</span>
              <v-divider class="my-2" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-if="loading">
        <v-row
          v-for="i in 3"
          :key="i.toString()"
          class="d-print-none"
          dense
        >
          <v-col
            v-for="a in 3"
            :key="a.toString()"
            cols="auto"
          >
            <v-skeleton-loader
              style="height: 80px;width: 280px"
              type="image"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row
          justify="center"
          justify-sm="start"
        >
          <template v-for="(s,index) in statistics">
            <template v-if="s.divider||s.title">
              <v-col
                :key="index.toString()"
                cols="12"
              >
                <v-divider
                  v-if="s.divider"
                  class="my-2"
                />
                <p
                  v-if="s.title"
                  class="mb-0 title"
                >
                  {{ s.title }}
                </p>
              </v-col>
            </template>
            <v-col
              v-else
              :key="index.toString()"
              cols="auto"
            >
              <static-card v-bind="s" />
            </v-col>
          </template>
        </v-row>
      </template>
    </v-col>
    <dialog-modal
      v-model="dialog"
      :fullscreen="AppIsSmall"
      no-click-animation
      persistent
    >
      <v-card
        class="d-print-none"
        flat
        tile
      >
        <v-card-title>{{ $t('filter') }}</v-card-title>
        <v-divider />
        <v-container fluid>
          <v-row>
            <app-date-picker
              v-model="form.from_date"
              clearable
              cols="12"
              hide-details
              lg="4"
              name="from_date"
              sm="6"
            />
            <app-date-picker
              v-model="form.to_date"
              clearable
              cols="12"
              hide-details
              lg="4"
              name="to_date"
              sm="6"
            />
            <app-companies
              v-if="isAdminUser"
              v-model="form.company_id"
              clearable
              cols="12"
              name="company_id"
              sm="6"
              lg="4"
              @set-items="companies = $event"
            />
          </v-row>
          <v-row>
            <v-col cols="auto">
              <app-btn
                :loading="loading"
                class="justify-center"
                color="primary"
                @click="doSearch({close:!0})"
              >
                <v-icon left>
                  search
                </v-icon>
                <span>{{ parseAttribute('do_search') }}</span>
              </app-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <app-btn
                class="justify-center"
                color="error"
                @click="closeDialog"
              >
                <v-icon left>
                  close
                </v-icon>
                <span>{{ parseAttribute('close') }}</span>
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </dialog-modal>
  </v-row>
</template>
<script>

import StaticCard from '@components/home-user/StaticCard'
import DialogModal from '@components/base/grid/DialogModal'
import SlideFadeTransition from '@components/SlideFadeTransition'
import SlideFadeTransitionGroup from '@components/SlideFadeTransitionGroup'

export default {
  name: 'PublicStatistics',
  components: { SlideFadeTransitionGroup, SlideFadeTransition, DialogModal, StaticCard },
  props: {
    print: {
      type: Function,
      required: !0,
      default: () => () => null
    }
  },
  data: () => ({
    dialog: !1,
    loading: !1,
    rows: [],
    companies: [],
    dates: [],
    form: {
      from_date: null,
      to_date: null,
      company_id: null
    }
  }),
  computed: {
    statistics () {
      return this.rows
    },
    companyName () {
      return this.companies.find(e => e.id === this.form.company_id)?.name
    }
  },
  mounted () {
    this.fetchAppointmentDates()
    this.$nextTick(() => this.doSearch())
  },
  methods: {
    fetchAppointmentDates () {
      this.apiService.appointmentDate.staticUtilities().then(r => {
        this.dates = r?._data || []
      })
    },
    doSearch (options) {
      options = options || {}
      if (this.loading) return
      this.loading = !0
      const params = { ...this.form }
      if (options?.excel === !0) {
        params.excel = 1
      }
      if (options.close === !0) this.closeDialog()
      return this.apiService.user.dashboard(params).then(({ _success, _data }) => {
        if (_success) {
          this.rows = _data?.rows || []
          if (_data?.url) {
            window.location.href = _data.url
            // window.open(_data.url, 'exportReport', 'left=0,location=no,width=20,height=20')
          }
        }
      }).finally(() => (this.loading = !1))
    },
    printReport () {
      this.print(1)
    },
    openDialog () {
      this.dialog = !0
    },
    closeDialog () {
      this.dialog = !1
    },
    setDate (e) {
      this.form = {
        ...this.form,
        from_date: e.date,
        to_date: e.date
      }
      this.$nextTick(() => this.doSearch({}))
    },
    clearDates () {
      this.form = {
        ...this.form,
        from_date: null,
        to_date: null
        // company_id: null
      }
      this.$nextTick(() => this.doSearch({}))
    }
  }
}
</script>
