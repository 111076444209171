/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  Auths: 'المصادقة|المصادقة',
  SideMenus: 'القائمة الجانبية|القائمة الجانبية',
  Ages: '{0}-|[1]سنة|[2]سنتان|[3,10]:age سنوات|[11,*] :age سنة',
  Days: '{0}-|[1]يوم|[2]يومان|[3,10]:days أيام|[11,*]:days يوم',
  Minutes: '{0}-|[1]دقيقة|[2]دقيقتان|[3,10]:minutes دقائق|[11,*] :minutes دقيقة',
  Hours: '{0}-|[1] ساعة|[2] ساعتان|[3,10]:hours ساعات|[11,*]:hours ساعة',
  Months: '{0}-|[1] شهر واحد|[2] شهرين|[3,10]:months أشهر|[11,*]:months شهر',
  MediaFiles: 'المرفق|المرفقات',
  Attachments: 'المرفق|المرفقات',
  Signatures: 'التوقيع|التواقيع',
  Permissions: 'الصلاحية|الصلاحيات',
  Roles: 'الوظيفة|الوظائف',
  Users: 'المستخدم|المستخدمون',
  Sales: 'الإيراد|الإيرادات',
  Expenses: 'الصرف|المصروفات',
  Utilities: 'أداة|أدوات',
  Notifications: 'الإشعار|الإشعارات',
  Settings: 'الإعداد|الإعدادات',
  Countries: 'الدولة|الدول',
  Cities: 'المدينة|المدن',
  Companies: 'الشركة العارضة|الشركات العارضة',
  Orders: 'الطلب|الطلبات',
  OrderStatuses: 'حالة الطلب|حالات الطلب',
  Categories: 'القسم|الأقسام',
  Sectors: 'القطاع|القطاعات',
  MeetingPurposes: 'الغرض من الاجتماع|الغرض من الاجتماع',
  AppointmentDates: 'تاريخ الموعد|تاريخ المواعيد',
  Appointments: 'الموعد|المواعيد',
  Surveys: 'الاستبيان|الاستبيانات'
}
