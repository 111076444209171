/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export const APP_ROUTES = {
  notFound: 'not-found',
  homePage: 'home-page',
  newSurvey: 'new-survey',
  hostedBuyersSurvey: 'hosted-buyers-survey',
  appointment: {
    newForm: 'appointment-new',
    inquiry: 'appointment-inquiry'
  },
  auth: {
    login: 'auth.login',
    otp: 'auth.loginOtp',
    register: 'auth.register',
    forgotPassword: 'auth.forgotPassword',
    logout: 'logout'
  },
  public: {
    aboutUs: 'aboutUs',
    contactUs: 'contactUs',
    privacyPolicy: 'privacyPolicy',
    termsAndConditions: 'termsAndConditions',
    contractSigning: 'contractSigning'
  },
  user: {
    home: 'panel.user.home',
    profile: 'panel.user.profile',
    index: 'panel.user.index',
    notifications: 'panel.notification.index'
  }
}
