<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<script>
import NumberInput from './NumberInput'

export default {
  name: 'MobileInput',
  components: { NumberInput },
  extends: NumberInput,
  props: {
    counter: {
      type: Boolean,
      default: () => !0
    }
  }
}
</script>
