<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-navigation-drawer
    :color="`primary ${themeLight ? 'darken-2' : 'darken-1'}`"
    :left="!AppRtl"
    :right="AppRtl"
    :value="value"
    app
    class="d-print-none"
    dark
    src="/assets/images/bg/artwork.png"
    touchless
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slide-fade-transition>
      <v-sheet
        v-if="isFetchItems"
        class="pa-0 pt-5"
        color="transparent"
      >
        <v-responsive
          class="mx-auto"
          max-width="100%"
        >
          <template v-for="i in 7">
            <v-skeleton-loader
              :key="i.toString()"
              :dark="themeDark"
              :light="themeLight"
              class="mx-auto mb-1 px-1"
              type="list-item-avatar"
            />
          </template>
        </v-responsive>
      </v-sheet>
      <app-drawer-list
        v-else
        :items="publicItems"
      />
    </slide-fade-transition>
    <v-divider class="primary" />
    <app-theme-list icon />
    <!--<v-divider class="primary" />-->
    <app-language-list icon />

    <template #prepend>
      <v-skeleton-loader
        v-if="isFetchItems"
        :dark="themeDark"
        :light="themeLight"
        class="px-1 pt-1"
        type="list-item-avatar-two-line"
      />
      <template v-else-if="isLogin && !isFetchItems">
        <v-list-item three-line>
          <v-list-item-avatar
            :color="authUser.avatar ? 'black' : undefined "
            :left="!AppRtl"
            :right="AppRtl"
            rounded
            size="60"
          >
            <v-img
              :src="authUserAvatar"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <router-link
                :to="route(APP_ROUTES.user.profile)"
                class="text-body-1 text-decoration-underline pointer"
              >
                {{ $t('replace.hello', { n: authUser.name }) }}
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ authUser.username }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="error--text pointer"
              @click="submitLogoutUser"
            >
              {{ $t('logout') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="primary" />
      </template>
    </template>

    <!--<template #append>
      <v-divider class="primary" />
      <app-theme-list
        icon
      />
      &lt;!&ndash;<v-divider class="primary" />&ndash;&gt;
      <app-language-list icon />
      &lt;!&ndash;<v-sheet
        v-if="isFetchItems"
        class="logout-sheet pa-2"
        color="transparent"
      >
        <v-skeleton-loader
          type="button"
        />
      </v-sheet>
      <template
        v-else-if="authUser"
      >
        <v-btn
          block
          tile
          @click.stop="submitLogoutUser"
          v-text="$t('logout')"
        />
      </template>&ndash;&gt;
    </template>-->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { hideHtmlOverflow, showHtmlOverflow } from '@helpers/Tools'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'Navigation',
  components: { SlideFadeTransition },
  props: {
    value: {
      type: Boolean,
      default: () => undefined
    }
  },
  data () {
    return {
      logoutLoading: !1
    }
  },
  computed: {
    isFetchItems: {
      set (v) {
        mapMutations('sideMenu', ['setFetching']).setFetching.call(this, v)
      },
      get () {
        return mapGetters('sideMenu', ['getFetching']).getFetching.call(this)
      }
    },
    publicItems () {
      const auth = [
        {
          name: this.APP_ROUTES.auth.login,
          title: this.$t('login'),
          // icon: '',
          center: !1,
          auth: !0
        },
        // {
        //   name: this.APP_ROUTES.auth.register,
        //   title: this.$t('register'),
        //   // icon: '',
        //   center: !1,
        //   auth: !0
        // },
        { divider: !0 }
      ]

      let items = [
        // {
        //   name: this.APP_ROUTES.homePage,
        //   title: this.$t('home'),
        //   icon: 'home'
        // },
        // {
        //   name: this.APP_ROUTES.public.aboutUs,
        //   title: this.$t(this.APP_ROUTES.public.aboutUs),
        //   icon: 'info'
        // },
        // {
        //   name: this.APP_ROUTES.public.contactUs,
        //   title: this.$t(this.APP_ROUTES.public.contactUs),
        //   icon: 'phone'
        // },
        // {
        //   name: this.APP_ROUTES.public.privacyPolicy,
        //   title: this.$t(this.APP_ROUTES.public.privacyPolicy),
        //   icon: 'privacy_tip'
        // },
        // {
        //   name: this.APP_ROUTES.public.termsAndConditions,
        //   title: this.$t(this.APP_ROUTES.public.termsAndConditions),
        //   icon: 'gavel'
        // }
      ]

      if (!this.authUser) {
        items = [...auth, ...items]
      }

      return [
        ...this.AppSideMenu, ...items]
    }

  },
  watch: {
    value (v) {
      if (v === !0) {
        hideHtmlOverflow()
      }
      if (v === !1) {
        showHtmlOverflow()
      }
    }
  },
  mounted () {
    // console.log(this.publicItems)
  }
}
</script>

<style scoped>
.logout-sheet .v-skeleton-loader__button {
  width: 100% !important;
}
</style>
