/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */
const primary = '#A81353'
const anchor = '#222020'
const accent = '#E52629'

const global = {
  primary,
  accent,
  anchor: '#f5f5f5',
  gold: '#C7A359',
  orange: '#f7941e',
  blue: '#1C75BC',
  pink: '#EC008C',
  yellow: '#efcc2a',
  'dark-blue': '#00838f',
  'light-blue': '#4cbac3',
  white: '#fafafa',
  footer: anchor,
  toolbar: primary,
  bar: primary
}

export const theme = {
  storageKey: 'theme_dark_storage',
  rtl: !0,
  dark: !1,
  themes: {
    light: {
      ...global
    },
    dark: {
      ...global
    }
  }
}

export const locale = {
  defaultLocale: 'ar',
  storageKey: 'locale_storage'
}

export const GOOGLE = {
  mapKey: '',
  defaultConfig: {
    zoom: 17,
    gestureHandling: 'cooperative'
  },
  libraries: [
    'visualization',
    'places'
  ].join(','),
  reCaptchaKey: ''
}

export const currency = {
  value: {
    code: 'SAR',
    short_name: 'ريال',
    balance: 1.0
  },
  storageKey: 'currency_storage'
}

export default { theme, locale, currency }
