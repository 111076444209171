<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row>
    <slot name="before" />
    <slot />
    <slot name="after" />
  </v-row>
</template>
<script>

import { CountryMixin } from '@mixins'

export default {
  name: 'PersonalForm',
  mixins: [CountryMixin],
  props: {
    value: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    form: {
      set (v) {
        this.$emit('input', v)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>
