<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :loading-datatable.sync="loadingDatatable"
      :form.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      pdf
      :excel="isAdminUser"
      control-width="auto"
      :item-class="(e) => e.is_pending === !0 ? 'yellow' : undefined"
      @submitClickForm="submitClickForm"
    >
      <template #top="v">
        <v-container>
          <v-row>
            <v-col
              v-if="userHasPermission('destroyAll')"
              cols="auto"
            >
              <app-btn
                :disabled="loadingDatatable"
                color="error"
                @click="destroySelected"
              >
                {{ $t('destroy') }}
              </app-btn>
            </v-col>
            <v-col cols="auto">
              <app-btn
                :disabled="disableTodayFilter || loadingDatatable"
                @click="setTodayAppointments"
              >
                {{ $t('custom.today_appointments') }}
              </app-btn>
            </v-col>
            <v-col cols="auto">
              <app-btn
                :disabled="disableTomorrowFilter || loadingDatatable"
                @click="setTomorrowAppointments"
              >
                {{ $t('custom.tomorrow_appointments') }}
              </app-btn>
            </v-col>
            <v-fade-transition>
              <v-col
                v-if="showClearCustomDates"
                cols="auto"
              >
                <app-btn
                  :disabled="loadingDatatable"
                  color="info"
                  @click="onClearCustomDates"
                >
                  {{ $t('show_all') }}
                </app-btn>
              </v-col>
            </v-fade-transition>
            <v-spacer />
            <template v-if="userHasPermission('fullStatistic')">
              <v-fade-transition>
                <v-col cols="auto">
                  <v-btn
                    dark
                    color="blue"
                    :disabled="loadingDatatable"
                    @click="onFullStatistic()"
                  >
                    <v-icon left>
                      analytics
                    </v-icon>
                    {{ $t('custom.appointment.statistic') }}
                    <span
                      v-if="v.getSelectedLength"
                      class="px-2"
                    >({{ v.getSelectedLength }})</span>
                  </v-btn>
                </v-col>
              </v-fade-transition>
            </template>
            <template v-if="userHasPermission('destroyAll')">
              <v-fade-transition>
                <v-col
                  v-if="showCanceledAppointments"
                  cols="auto"
                >
                  <app-btn
                    :disabled="loadingDatatable"
                    @click="onShowCanceledAppointments(1)"
                  >
                    {{ $t('custom.canceled_appointments') }}
                  </app-btn>
                </v-col>
                <v-col
                  v-else
                  cols="auto"
                >
                  <app-btn
                    :disabled="loadingDatatable"
                    color="success"
                    @click="onShowCanceledAppointments(0)"
                  >
                    {{ $t('custom.all_appointments') }}
                  </app-btn>
                </v-col>
              </v-fade-transition>
            </template>
          </v-row>
        </v-container>
      </template>
      <template #filter="{datatableFilters}">
        <v-container>
          <v-row>
            <app-appointment-dates
              name="appointment_dates"
              sm="6"
              item-text="date"
              item-value="date"
              class="d-none"
              @set-items="appointmentDates = $event"
            />
            <app-auto-select
              v-model="datatableFilters.from_date"
              clearable
              cols="12"
              name="from_date"
              sm="6"
              item-text="date"
              item-value="date"
              :items="appointmentDates"
            />
            <app-auto-select
              v-model="datatableFilters.to_date"
              clearable
              cols="12"
              name="to_date"
              sm="6"
              item-text="date"
              item-value="date"
              :items="appointmentDates"
            />
            <!--<app-date-picker
              v-model="datatableFilters.from_date"
              clearable
              cols="12"
              name="from_date"
              sm="6"
            />
            <app-date-picker
              v-model="datatableFilters.to_date"
              clearable
              cols="12"
              name="to_date"
              sm="6"
            />-->
          </v-row>
          <v-row>
            <app-auto-select
              v-model="datatableFilters.from_time"
              clearable
              cols="12"
              name="from_time"
              sm="6"
              :items="appointmentTimes"
            />
            <app-auto-select
              v-model="datatableFilters.to_time"
              clearable
              cols="12"
              name="to_time"
              sm="6"
              :items="appointmentTimes"
            />
            <!--<app-time-picker-->
            <!--  v-model="datatableFilters.from_time"-->
            <!--  force-clearable-->
            <!--  cols="12"-->
            <!--  name="from_time"-->
            <!--  sm="6"-->
            <!--  :allowed-minutes="allowedMinutes"-->
            <!--/>-->
            <!--<app-time-picker-->
            <!--  v-model="datatableFilters.to_time"-->
            <!--  force-clearable-->
            <!--  clearable-->
            <!--  cols="12"-->
            <!--  name="to_time"-->
            <!--  sm="6"-->
            <!--  :allowed-minutes="allowedMinutes"-->
            <!--/>-->
          </v-row>
          <v-row>
            <app-companies
              v-if="isAdminUser"
              v-model="datatableFilters.company_id"
              clearable
              cols="12"
              name="company_id"
              sm="6"
              multiple
            />
            <app-countries
              v-model="datatableFilters.country_id"
              clearable
              cols="12"
              name="country_id"
              sm="6"
            />
            <app-appointment-statuses
              v-if="isAdminUser"
              v-model="datatableFilters.status"
              clearable
              cols="12"
              name="status"
              sm="6"
              multiple
            />
            <app-sectors
              v-model="datatableFilters.sectors_id"
              clearable
              cols="12"
              name="sectors_id"
              sm="6"
              multiple
            />
            <!--
            <app-meeting-purposes
              v-model="datatableFilters.meeting_purpose_id"
              clearable
              cols="12"
              name="meeting_purpose_id"
              sm="6"
            />-->
          </v-row>
        </v-container>
      </template>

      <!--<template #form="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-container>
            <v-row>
              <app-text-input
                v-model="selectedItem.name"
                cols="12"
                name="name"
                required
                sm="6"
              />
              <app-number-input
                v-model="selectedItem.mobile"
                cols="12"
                name="mobile"
                required
                sm="6"
              />
            </v-row>
            <v-row>
              <app-switcher
                v-model="selectedItem.active"
                cols="auto"
                name="active"
              />
            </v-row>
          </v-container>
        </app-form>
      </template>-->

      <template #item.control="{item,index}">
        <template v-if="item.is_pending === !0">
          <app-dt-btn
            v-if="item.is_approved === !1 && userHasPermission('approve')"
            :disabled="loadingDatatable"
            @click="approve(item,index)"
          >
            <v-icon color="success">
              event_available
            </v-icon>
          </app-dt-btn>
          <app-dt-btn
            v-if="item.is_rejected === !1 && userHasPermission('reject')"
            :disabled="loadingDatatable"
            @click="reject(item,index)"
          >
            <v-icon color="error">
              event_busy
            </v-icon>
          </app-dt-btn>
          <app-dt-btn
            v-if="item.is_pending === !1 && userHasPermission('pending')"
            :disabled="loadingDatatable"
            @click="pending(item,index)"
          >
            <v-icon color="warning">
              event_repeat
            </v-icon>
          </app-dt-btn>
        </template>

        <v-tooltip
          top
        >
          <template #activator="{attrs,on}">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <app-dt-btn
                v-if="userHasPermission('sendAllNotifications')"
                :loading="loadingDatatable"
                :disabled="loadingDatatable"
                @click="sendAllNotifications(item,index)"
              >
                <v-icon color="primary">
                  send
                </v-icon>
              </app-dt-btn>
            </span>
          </template>
          <template #default>
            <span>{{ $t('custom.appointment.test_notifications') }}</span>
          </template>
        </v-tooltip>
        <!--<app-dt-btn-->
        <!--  v-if="userHasPermission('update')"-->
        <!--  :disabled="loadingDatatable"-->
        <!--  update-->
        <!--  @click="showUpdateDialog(item)"-->
        <!--/>-->
        <!--<app-dt-btn-->
        <!--  v-if="userHasPermission('destroy')"-->
        <!--  :disabled="loadingDatatable"-->
        <!--  destroy-->
        <!--  @click="deleteItem(item)"-->
        <!--/>-->
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'

export default {
  name: 'Index',
  helperApiName: 'appointment',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Appointment',
      loadingDatatable: !1,
      todayDate: this.$moment().locale('en').format('YYYY-MM-DD'),
      tomorrowDate: this.$moment().add('d', 1).locale('en').format('YYYY-MM-DD'),
      appointmentDates: [],
      appointmentTimes: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'control',
          value: 'control',
          sortable: !1,
          width: 'auto'
        },
        {
          text: 'name',
          value: 'name',
          sortable: !1,
          width: 150
        },
        {
          text: 'email',
          value: 'email',
          sortable: !1,
          width: 80
        },
        {
          text: 'mobile_to_string',
          value: 'mobile_to_string',
          sortable: !1,
          width: 130
        },
        {
          text: 'job_title',
          value: 'job_title',
          sortable: !1
        },
        {
          text: 'company_name',
          value: 'company_name',
          sortable: !1
        },
        {
          text: 'website',
          value: 'website',
          sortable: !1
        },
        {
          text: 'country_id_to_string',
          value: 'country_id_to_string',
          sortable: !1
        },
        {
          text: 'city',
          value: 'city',
          sortable: !1
        },
        (this.isAdminUser
          ? {
              text: 'company_id_to_string',
              value: 'company_id_to_string',
              sortable: !1,
              width: 100
            }
          : undefined),
        {
          text: 'date',
          value: 'date',
          width: 150
        },
        {
          text: 'time',
          value: 'time',
          width: 100
        },
        (this.isAdminUser ? 'status_to_string' : undefined),
        {
          text: 'sectors_id_to_string',
          value: 'sectors_id_to_string',
          sortable: !1
        },
        {
          text: 'meeting_purpose_id_to_string',
          value: 'meeting_purpose_id_to_string',
          sortable: !1
        }
      ]
    },
    disableTodayFilter () {
      if (!this.$refs.datatable?.datatableFilters) {
        return !1
      }
      const { datatableFilters } = this.$refs.datatable
      return datatableFilters.from_date === this.todayDate && datatableFilters.to_date === this.todayDate
    },
    disableTomorrowFilter () {
      if (!this.$refs.datatable?.datatableFilters) {
        return !1
      }
      const { datatableFilters } = this.$refs.datatable
      return datatableFilters.from_date === this.tomorrowDate && datatableFilters.to_date === this.tomorrowDate
    },
    showClearCustomDates () {
      if (!this.$refs.datatable?.datatableFilters) {
        return !1
      }
      const { datatableFilters } = this.$refs.datatable
      return datatableFilters?.from_date === datatableFilters?.to_date && datatableFilters?.from_date && datatableFilters?.to_date
    },
    showCanceledAppointments () {
      if (!this.$refs.datatable?.datatableOptions) {
        return !1
      }
      const { datatableOptions } = this.$refs.datatable
      return parseInt(datatableOptions.canceled_appointments) !== 1
    }
  },
  mounted () {
    this.checkPermission('index')
    this.fetchAppointmentTimes()
  },
  methods: {
    async fetchAppointmentTimes () {
      try {
        const { _data } = await this.apiService.appointment.appointmentsTimes()
        this.appointmentTimes = _data || []
      } catch (e) {
        const { _message } = e || {}
        _message && this.alertError(_message)
      }
    },
    allowedMinutes (v) {
      return v === 0 || v === 30
    },
    appointmentAction (type = null, { item, index }) {
      if (this.loadingDatatable || !type) return
      this.confirmMessage(async () => {
        this.loadingDatatable = !0
        // console.log(type)
        try {
          const { _data, _message, _success } = await this.apiService.appointment[type](item.id)
          _message && this.alertSuccess(_message)
          _data && _success === !0 && this.updateDatatableItem(_data, index)
        } catch (e) {
          const { _message } = e || {}
          _message && this.alertError(_message)
        } finally {
          this.loadingDatatable = !1
        }
      })
    },
    approve (item, index) {
      return this.appointmentAction('approve', { item, index })
    },
    reject (item, index) {
      return this.appointmentAction('reject', { item, index })
    },
    pending (item, index) {
      return this.appointmentAction('pending', { item, index })
    },
    sendAllNotifications (item, index) {
      return this.appointmentAction('sendAllNotifications', { item, index })
    },
    setTodayAppointments () {
      this.$refs.datatable.setDatatableFilters({
        from_date: this.todayDate,
        to_date: this.todayDate
      })
    },
    setTomorrowAppointments () {
      this.$refs.datatable.setDatatableFilters({
        from_date: this.tomorrowDate,
        to_date: this.tomorrowDate
      })
    },
    onClearCustomDates () {
      this.$refs.datatable.setDatatableFilters({
        from_date: null,
        to_date: null
      })
      // this.$refs.datatable.setDatatableOptions({
      //   canceled_appointments: undefined
      // })
    },
    onShowCanceledAppointments (v) {
      // this.$refs.datatable.setDatatableFilters({
      //   from_date: null,
      //   to_date: null
      // })
      this.$refs.datatable.setDatatableOptions({
        canceled_appointments: parseInt(v)
      })
    },
    destroySelected () {
      if (this.$refs.datatable) {
        if (this.loadingDatatable) return
        this.confirmMessage(async () => {
          this.loadingDatatable = !0
          const dt = this.$refs.datatable
          const ids = dt.selectedItems ? dt.selectedItems.map(e => e.id) : []
          try {
            const { _success, _message } = await this.apiService.appointment.destroyAll(ids)
            _message && this.alertSuccess(_message)
            if (_success === !0) {
              this.$nextTick(() => dt.refresh())
            }
          } catch (e) {
            const { _message } = e
            _message && this.alertError(_message)
          } finally {
            this.loadingDatatable = !1
          }
          // console.log(ids)
        })
      }
    },
    onFullStatistic () {
      const dt = this.$refs.datatable
      if (dt) {
        if (this.loadingDatatable) return
        this.loadingDatatable = !0
        try {
          dt.exportExcel(this.apiService.appointment.fullStatistic(!0))
        } catch (e) {
          e?.message && this.alertError(e.message)
        } finally {
          this.loadingDatatable = !1
        }
      }
    }
  }
}
</script>
