/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { camelCase, isArray, isObject, startsWith, upperFirst } from 'lodash'

const
  messages = {}
const locales = []
const requireModule = require.context('@locales', true, /\.js$/)
const toUpper = (string) => {
  if (isObject(string) || isArray(string)) {
    for (const s in string) { string[s] = toUpper(string[s]) }
    return string
  } else {
    return upperFirst(string)
  }
}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const locale = fileName.replace(/(\.\/|\.js)/g, '').split('/')[0]
  !messages[locale] && (messages[locale] = {})

  const
    file = fileName.split('/').slice(-1)[0].replace(/(\.\/|\.js)/g, '')
  const m = requireModule(fileName)
  let l = m.default || m
  // console.log(file)
  l = toUpper(l)

  if (file.toLowerCase() === locale.toLowerCase() || file.toLowerCase() === 'global') {
    messages[locale] = {
      ...messages[locale],
      ...l
    }
  } else {
    let key = camelCase(file)
    if (startsWith(file, '$')) { key = `${file}` }

    messages[locale][key] = l
  }
  locales.indexOf(locale) < 0 && locales.push(locale)
})
// console.log(locales)
export default messages
export { messages, locales }
