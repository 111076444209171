<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-btn
    :class="getClasses"
    small
    :min-width="minWidth"
    :outlined="getOutlined"
    :text="getText"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon
      v-if="update"
      color="success"
    >
      edit
    </v-icon>
    <v-icon
      v-if="show"
      color="primary"
    >
      visibility
    </v-icon>
    <v-icon
      v-if="destroy"
      color="error"
    >
      delete
    </v-icon>
    <slot />
  </v-btn>
  <!--<app-tooltip
    :disabled="tooltipDisabled"
    :text="getTooltip"
  >
    <v-btn
      :class="getClasses"
      :min-width="minWidth"
      v-bind="$attrs"
      :outlined="getOutlined"
      :text="getText"
      :icon="getIcon"
      v-on="$listeners"
    >
      <template v-if="update">
        <v-icon color="success">
          edit
        </v-icon>
      </template>
      <template v-if="show">
        <v-icon color="primary">
          visibility
        </v-icon>
      </template>
      <template v-if="destroy">
        <v-icon color="error">
          delete
        </v-icon>
      </template>
      <slot />
    </v-btn>
  </app-tooltip>-->
</template>

<script>
export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    update: {
      type: Boolean,
      default: () => false
    },
    show: {
      type: Boolean,
      default: () => false
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    tooltip: {
      type: String,
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'ma-1'
      // 'me-1',
      // 'my-1'
    ].join(' '),
    getMinWidth () {
      return this.$attrs.icon === undefined ? this.minWidth : undefined
    },
    getIcon () {
      return this.$attrs.icon === undefined ? !0 : this.$attrs.icon
    },
    getText () {
      return this.$attrs.text === undefined ? !0 : this.$attrs.text
    },
    getOutlined () {
      return this.$attrs.outlined === undefined ? !0 : this.$attrs.outlined
    },
    getTooltip () {
      if (this.tooltip) return this.parseAttribute(this.tooltip)
      if (this.update) return this.$t('update')
      if (this.show) return this.$t('show')
      if (this.destroy) return this.$t('destroy')
      return null
    },
    tooltipDisabled () {
      if (this.AppIsSmall) return !0
      return this.getTooltip === null || this.getTooltip === undefined
    }
  }
}
</script>
