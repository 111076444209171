<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      v-slot="v"
      ref="provider"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <ckeditor
              :config="geConfig"
              :label="getLabel"
              :placeholder="getPlaceholder"
              :value="value"
              v-bind="$attrs"
              @input="$emit('input',$event)"
              v-on="$listeners"
            />
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import TextInput from '@components/base/inputs/TextInput'

export default {
  name: 'TextEditor',
  extends: TextInput,
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({}),
  computed: {
    geConfig () {
      const language = this.AppLocale
      return {
        defaultLanguage: language,
        language,
        contentsLangDirection: this.AppDirection,
        ...this.config
      }
    }
  }
}
</script>
