/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  name: 'إعداد المنصة',
  general: 'عام',
  expo: 'المعرض',
  new_appointment: 'إضافة موعد/رجل الأعمال',
  new_appointment_company: 'إضافة موعد/الشركة العارضة',
  approve_appointment: 'قبول موعد/رجل الأعمال',
  reject_appointment: 'رفض موعد/رجل الأعمال',
  no_appointments: 'لا توجد مواعيد/رجل الأعمال',
  messages_content: 'محتوى الرسائل'
}
